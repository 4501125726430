import { FunctionComponent, useEffect, useState } from "react";
import { Badge, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./CookieAlertComponent.module.scss";
import Switch from "react-switch";
import configurationService from "../../services/configuration/configuration.service";
import { toast } from "react-toastify";

export interface CookieAlertComponentProps {
    onConfirm: any;
    show: boolean;
}
enum CookieTab {
    YourSettings = "YourSettings",
    NecessaryCookies = "NecessaryCookies",
    Marketing = "Marketing",
    Functional = "Functional"
}
const CookieAlertComponent: FunctionComponent<CookieAlertComponentProps> = (props) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<CookieTab>(CookieTab.YourSettings);
    const [marketingCookiesActive, setMarketingCookiesActive] = useState<boolean>(false);
    const [functionalCookiesActive, setFunctionalCookiesActive] = useState<boolean>(false);
    const [policyUrl, setPolicyUr] = useState<string>("");


    const getSelectedCookies = () => {
        let array = [];
        if (functionalCookiesActive)
            array.push("Functional");
        if (marketingCookiesActive)
            array.push("Marketing");
        return array;
    }
    useEffect(() => {
        configurationService.getSetting("Urls.PRIVACY_POLICY_SV").then(res => {
            setPolicyUr(res.value);
        },
            error => {
                // toast.error(error.response.data.message ? error.response.data.message.interpolateError() : error.message.interpolateError());
            });
    }, [])
    return <Modal show={props.show}
        // onHide={ }
        animation={false}
        size={"lg"}
        aria-labelledby="contained-modal-title-vcenter"
        className="dashboard-my-modal"
        centered>
        <Modal.Body className="px-0 py-0">
            <div className={styles.cookieAlertWrap}>
                <div className={styles.cookieAlertTabs}>
                    <div className={activeTab === CookieTab.YourSettings ? `${styles.cookieTab} ${styles.activeTab}` : styles.cookieTab}
                        onClick={() => { setActiveTab(CookieTab.YourSettings) }} >
                        {t("Hints.YourSettings")}
                    </div>
                    <div className={activeTab === CookieTab.NecessaryCookies ? `${styles.cookieTab} ${styles.activeTab}` : styles.cookieTab}
                        onClick={() => { setActiveTab(CookieTab.NecessaryCookies) }}>
                        {t("Hints.NecessaryCookies")}
                    </div>
                    <div className={activeTab === CookieTab.Marketing ? `${styles.cookieTab} ${styles.activeTab}` : styles.cookieTab}
                        onClick={() => { setActiveTab(CookieTab.Marketing) }}>
                        {t("Hints.Marketing")}
                    </div>
                    <div className={activeTab === CookieTab.Functional ? `${styles.cookieTab} ${styles.activeTab}` : styles.cookieTab}
                        onClick={() => { setActiveTab(CookieTab.Functional) }}>
                        {t("Hints.Functional")}
                    </div>
                </div>
                <div className="w-100">
                    <div className={styles.cookieAlertTabContent}>
                        <h2>{t("Titles.SettingsForCookies")}</h2>
                        <br />

                        {
                            activeTab == CookieTab.YourSettings ? <>

                                <h5><b>{t("Hints.YourSettings")}</b></h5>
                                <br />
                                <p>{t("Hints.CookiesYourSettingsDescription")}</p>
                                <a href={policyUrl}>{t("Hints.ReadMore")}</a></>
                                : activeTab == CookieTab.NecessaryCookies ? <>
                                    <h5><b>{t("Hints.NecessaryCookies")}</b> <Badge bg="success">{t("Hints.AlwaysActive")}</Badge> </h5>
                                    <br />
                                    <p>{t("Hints.CookiesNecessaryDescription")}</p>
                                </> : activeTab == CookieTab.Marketing ? <>
                                    <h5><b>{t("Hints.Marketing")}</b>
                                        {/*@ts-ignore*/}
                                        <Switch uncheckedIcon={false} checkedIcon={false} className="float-right" onChange={(value) => { setMarketingCookiesActive(value) }} checked={marketingCookiesActive} />
                                    </h5>
                                    <br />
                                    <p>{t("Hints.CookiesMarketingDescription")}</p>
                                </> : activeTab == CookieTab.Functional ? <>
                                    <h5><b>{t("Hints.Functional")}</b>
                                        {/*@ts-ignore*/}
                                        <Switch uncheckedIcon={false} checkedIcon={false} className="float-right" onChange={(value) => { setFunctionalCookiesActive(value) }} checked={functionalCookiesActive} />
                                    </h5>
                                    <br />
                                    <p>{t("Hints.CookiesFunctionalDescription")}</p>
                                </> : <></>
                        }
                    </div>


                </div>

            </div>
                  <div className={styles.cookieActions}>
                         <Button type="button" variant="info" onClick={() => { props.onConfirm(getSelectedCookies()) }} className="px-2 py-2 mx-2">
                             {t("Actions.AllowSelected")}</Button>
                         <Button type="button" variant="success" onClick={() => { props.onConfirm(["Functional", "Marketing"]) }} className="px-2 py-2 mx-2">
                             {t("Actions.AllowAll")}</Button>
                     </div>
        </Modal.Body>
    </Modal>
}
export default CookieAlertComponent;
