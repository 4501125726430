import { useContext } from "react";
import { LocationInput } from "../../components/searchCard/models/inputs.model";
import { RouteResponse } from "../../models/dto/map/routeRequest.model";
import authService from "../../services/auth/auth.service";

const getActiveOrganizationId = (): number => {
    const organizationId = localStorage.getItem("activeOrganizationId");
    if (organizationId)
        return +organizationId;
    else
        return 0;
}

const trafficControlHelper = {
    getActiveOrganizationId
};
export default trafficControlHelper;