import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useHistory, NavLink } from "react-router-dom";
import { BoxArrowInRight, BoxArrowLeft, Globe } from 'react-bootstrap-icons';
import logo from "../../../assets/images/bookabus_logo_black.svg";
import lightLogo from "../../../assets/images/bookabus_logo_white.svg";
import { Form, Modal, Nav, NavDropdown, Navbar, } from "react-bootstrap";
import { useState, useEffect } from "react";
import authService from "../../services/auth/auth.service";
import axios, { AxiosResponse } from "axios";
import styles from "./header.module.scss";
import { RoutesContext } from "../../contexts/routes.context";
import { slide as Menu } from 'react-burger-menu'
import trafficControlHelper from "../../helpers/trafficControlHelper/routeHelper";
import { DealOrganization } from "../../models/dto/dealOrganization/dealOrganization.dto";
import { UserRole } from "../../models/dto/users/user.dto";
import apiHelper from "../../helpers/apiHelper/apiHelper";
import dealOrganizationService from "../../services/dealOrganizations/dealOrganization.service";


///// Component stylesheet can be found in assets/partials/components because of miltuiple mode and overrided styles with bootstrap
interface HeaderInfo {
  navMenuItems: NavMenuItem[];
  hamburgerMenuItems: { title: string; isInternal?: boolean; link: string; }[];
  loginUrl: string;
}
export interface NavMenuItem {
  title: string;
  link: string;
  subItems?: NavMenuItem[];
}
const Header: FunctionComponent<HeaderInfo> = (props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { setLanguage } = useContext(RoutesContext);
  const isIframe: boolean = Boolean(new URLSearchParams(location.search).get("isIframe")) ?? false;
  const customerType = localStorage.getItem("customerType") ?? "CORPORATE";

  const changeLanguage = (languageCode: string) => {
    const oldLanguage = i18n.language;
    setLanguage(languageCode);
    i18n.changeLanguage(languageCode);
    if (window.location.href.includes(window.location.host + "/" + oldLanguage)) {
      window.location.replace(window.location.href.replace(window.location.host + "/" + oldLanguage, window.location.host + "/" + languageCode));
    }
    else {
      window.location.replace(window.location.href.replace(window.location.host, window.location.host + "/" + languageCode));
    }
  }
  const activeOrganizationId = trafficControlHelper.getActiveOrganizationId();
  const [assignedTrainOrganizations, setAssignedTrainOrganizations] = useState<DealOrganization[]>();
  const setTrafficControlActiveOrganization = (id: number) => {
    localStorage.setItem("activeOrganizationId", id.toString());
    window.location.reload();
  }
  const isMainPage = () => {
    if (location.pathname == "/" + i18n.language || location.pathname == "/" + i18n.language + "/" || location.pathname == "/")
      return true
    return false;
  }
  const isSearchPage = () => {
    if (location.pathname.includes("buses/search"))
      return true
    return false;
  }
  const [navExpanded, setNavExpanded] = useState<boolean>(false);
  const [isLightMode, setIsLightMode] = useState<boolean>(false);
  const [isFixed, setIsFixed] = useState<boolean>(false);

  const [appVersion, setAppVersion] = useState<string>("");
  const getAppVersion = () => {
    axios.get<number, AxiosResponse<string>>
      (`${window.config.baseApiUrl}/version`, {})
      .then((response) => {
        setAppVersion(response.data);
      });
  }

  useEffect(() => {
    getAppVersion();
    if (authService.isUserInRole(UserRole.TrafficController)) {
      getAssignedTrainOrganizations();
    }
  }, [])
  const getAssignedTrainOrganizations = async () => {
    await apiHelper.requestAsync(async () => {
      const trainOrgs = await dealOrganizationService.getTrainOrganizationsOfTrafficControlUser();
      setAssignedTrainOrganizations(trainOrgs);
    }, () => { }, []);
  }
  useEffect(() => {
    setIsLightMode(isMainPage());
    setIsFixed(isSearchPage());
  }, [location.pathname])
  return <> <div className={'header-wrap ' + (isLightMode ? 'light-header ' : 'dark-header ') + (isFixed ? 'fixed-header' : '')}
  >
    <div id="hiddenDiv" style={{ position: "fixed", color: "transparent" }}></div>

    <Navbar collapseOnSelect={false} expand="md" variant="dark" className="justify-content-between mt-0" expanded={navExpanded}>

      <Navbar.Brand className="header-logo-wrap">

        <Menu>
          {props.hamburgerMenuItems.map((menuItem) => {
            return <a className="menu-item" href={menuItem.link}>{menuItem.title}</a>
          }
          )}
          <div className="menu-setings-wrap" >
            <a className="menu-item" href="#" onClick={() => { i18n.language === "en" ? changeLanguage("sv") : changeLanguage("en") }}>{t("Hints.Language")}: {i18n.language}</a>
            <div>
              <a className={customerType == "CORPORATE" ? "selected mr-2" : "mr-2"} onClick={() => { localStorage.setItem("customerType", "CORPORATE"); window.location.reload(); }}>{t("Hints.CORPORATE")}</a>
              <span className="menu-item">|</span>
              <a className={customerType == "PRIVATE" ? "selected mx-2" : "mx-2"} onClick={() => { localStorage.setItem("customerType", "PRIVATE"); window.location.reload(); }}>{t("Hints.PRIVATE")}</a>
            </div>
            {authService.isAuthenticated() ? <a className="menu-item" href="#" onClick={() => { authService.logout(); window.location.href = props.loginUrl }}>{t("Actions.Logout")}</a>
              : <Link to={props.loginUrl}>
                {t("Actions.Login")}</Link>}
          </div>

        </Menu>
        <a href={"/" + i18n.language + "#"}>
          {isLightMode && <img src={lightLogo} alt={t("Titles.Bookabus")} />}
          {!isLightMode &&
            <img src={logo} alt={t("Titles.Bookabus")} />}
        </a>

      </Navbar.Brand>
      <Navbar.Brand className={isLightMode ? styles.versionInfoLight : styles.versionInfoDark}>{window.config.environment != "production" ? <span> v{appVersion}</span> : <></>}</Navbar.Brand>
      {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => { setNavExpanded(!navExpanded) }} /> */}
      <Navbar.Collapse id="responsive-navbar-nav" className={'mt-0 flex-grow-0'}>
        <Nav className="mr-auto">
          {props.navMenuItems.map((menuItem) => {
            return isIframe ? <a className="nav-link" href={menuItem.link} target="_blank">{t(menuItem.title)}</a> : <Nav.Link onClick={() => { setNavExpanded(false) }}>
              <Link to={menuItem.link} rel="noopener noreferrer">{t(menuItem.title)}</Link>
            </Nav.Link>

          }


            /// Todo : Implement sub menus if needed
          )}
          {/* {authService.isAuthenticated() ? <div className="responsive-menu-items">
            <Nav.Link onClick={() => { authService.logout(); window.location.href = props.loginUrl }}>
              {t("Actions.Logout")}</Nav.Link>
          </div> : <div className="responsive-menu-items">
            {isIframe ? <a className="nav-link" href={props.loginUrl} target="_blank">{t("Actions.Login")}</a> : <Nav.Link onClick={() => { setNavExpanded(false) }}><Link to={props.loginUrl}>
              {t("Actions.Login")}</Link></Nav.Link>}

          </div>} */}

        </Nav>
      </Navbar.Collapse>
      <Nav className="header-actions-wrap">
        {authService.isTrafficControlUser() && <NavDropdown
          id="nav-dropdown"
          title={assignedTrainOrganizations?.find(t => t.id == +activeOrganizationId)?.name}
          
        >
          {assignedTrainOrganizations?.map(org => {
            return <NavDropdown.Item
              onClick={() => { setTrafficControlActiveOrganization(org.id ?? 0) }}>
              {org.name}</NavDropdown.Item>
          })}
        </NavDropdown>}

        {authService.isAuthenticated() ?
          <Nav.Link onClick={() => { authService.logout(); window.location.href = props.loginUrl }} className="header-action"> <BoxArrowLeft className="header-icon" />
            {t("Actions.Logout")}</Nav.Link> :
          isIframe ? <a className="nav-link header-action" href={props.loginUrl} target="_blank"><BoxArrowInRight className="header-icon" /> {t("Actions.Login")}</a> :
            <><div className="header-customer-type-wrap mx-3">
              <a className={customerType == "CORPORATE" ? "selected mx-2" : "mx-2"} onClick={() => { localStorage.setItem("customerType", "CORPORATE"); window.location.reload(); }}>{t("Hints.CORPORATE")}</a> <span>|</span>
              <a className={customerType == "PRIVATE" ? "selected mx-2" : "mx-2"} onClick={() => { localStorage.setItem("customerType", "PRIVATE"); window.location.reload(); }}>{t("Hints.PRIVATE")}</a>
            </div><Nav.Link><Link className="header-action" to={props.loginUrl}> <BoxArrowInRight className="header-icon" />
              {t("Actions.Login")}</Link></Nav.Link></>
        }
        <Nav.Link eventKey={2}>
          <a className="header-action" onClick={() => { i18n.language === "en" ? changeLanguage("sv") : changeLanguage("en") }}>
            <Globe className="header-icon" />
            {i18n.language}</a>
        </Nav.Link>
      </Nav>
    </Navbar>
  
    {/* <span className={isLightMode ? styles.versionInfoLight : styles.versionInfoDark}> v{appVersion}</span> */}
  </div>
  </>
}

export default Header;