import { AxiosHeaders } from "axios";
import trafficControlHelper from "../trafficControlHelper/routeHelper";

export function authHeader(): AxiosHeaders {
    // return authorization header with jwt token
    let user = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token') ?? "") : "";
    if (user && user.token) {
        return new AxiosHeaders({ Authorization: 'Bearer ' + user.token, 'Customer-Organization-Id': trafficControlHelper.getActiveOrganizationId().toString() });
    } else {
        return new AxiosHeaders({});
    }
}
