import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { authHeader } from "../../helpers/authHeader/auth-header";
import { Accessibility } from "../../models/dto/configuration/accessibility.dto";
import { CancellationFee, CancellationFeeListItemDto } from "../../models/dto/configuration/cancellationFees.dto";
import { SettingItemDto } from "../../models/dto/configuration/generalSettings.dto";
import { CountryDto } from "../../models/dto/shared/country.dto";
const API_URL = window.config.baseApiUrl;

const useCancellationFees = () => {
    return useQuery<CancellationFeeListItemDto[]>(
        ['cancellationFees'],
        async () => {
            const res = await axios.get<any, AxiosResponse<CancellationFeeListItemDto[]>>(`${API_URL}/cancellation/fee/all`,
                { headers: authHeader() });
            return res.data;
        },
        { keepPreviousData: true }
    );
}
const createCancellationFee = (cancellationFee: CancellationFee): Promise<CancellationFee> => {
    return axios
        .post<CancellationFee, AxiosResponse<CancellationFee>>(`${API_URL}/cancellation/fee`, cancellationFee, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const updateCancellationFee = (cancellationFee: CancellationFee): Promise<CancellationFee> => {
    return axios
        .put<CancellationFee, AxiosResponse<CancellationFee>>(`${API_URL}/cancellation/fee`, cancellationFee, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const deleteCancellationFee = (id: number) => {
    return axios
        .delete<number>(`${API_URL}/cancellation/fee/${id}`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const getCancellationFee = (id: number): Promise<CancellationFee> => {
    return axios.get<any, AxiosResponse<CancellationFee>>(`${API_URL}/cancellation/fee/${id}`,
        { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const updateAccessibility = (accessibility: Accessibility): Promise<Accessibility> => {
    return axios
        .put<Accessibility, AxiosResponse<Accessibility>>(`${API_URL}/accessibility`, accessibility, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const getAccessibility = (companyId?:string): Promise<Accessibility> => {
    return axios
        .get<Accessibility, AxiosResponse<Accessibility>>(`${API_URL}/accessibility`, { params:{ companyId },  headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const getCountries = (): Promise<CountryDto[]> => {
    return axios
        .get<null, AxiosResponse<CountryDto[]>>(`${API_URL}/country/all`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const getGeneralSettings = (): Promise<SettingItemDto[]> => {
    return axios
        .get<null, AxiosResponse<SettingItemDto[]>>(`${API_URL}/setting`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const getSetting = (settingKey: string): Promise<SettingItemDto> => {
    return axios
        .get<string, AxiosResponse<SettingItemDto>>(`${API_URL}/setting/${settingKey}`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const updateGeneralSettings = (settings: SettingItemDto[]): Promise<string> => {
    return axios
        .put<SettingItemDto[], AxiosResponse<string>>(`${API_URL}/setting`, settings, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
const configurationService = {
    useCancellationFees,
    createCancellationFee,
    getCancellationFee,
    updateCancellationFee,
    deleteCancellationFee,
    updateAccessibility,
    getAccessibility,
    getCountries,
    getGeneralSettings,
    getSetting,
    updateGeneralSettings
};

export default configurationService;
