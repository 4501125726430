import { BaseEntityDto } from "../shared/base.dto";
import { BaseListDto } from "../shared/baseList.dto";
import { BaseListRequest } from "../shared/baseListRequest.dto";

export interface UserListDto extends BaseListDto {
    data: UserListItemDto[];
}
export interface UserListItemDto extends User {
    profilePic: string;
    type: "PASSENGER" | "DRIVER" | "COMPANY_ADMIN" | "SYSTEM_ADMIN";
}
export enum UserStatus {
    Active = "STATUS_ACTIVE",
    DeActive = "STATUS_INACTIVE"
}
export interface User extends BaseEntityDto {
    username: string;
    name: string;
    email: string;
    phone: string;
    password?: string;
    type?: string;
    companyId?: number;
    customerOrganizationId?: number;
    creationTime: string;
    trafficCompanyIds?: number[];
    status: UserStatus;
}
export interface UserSearchRequest extends BaseListRequest {
    name: string;
    email: string;
    phone: string;
    userType?: string;
}
export enum UserRole {
    Customer = 'CUSTOMER',
    Driver = 'DRIVER',
    CompanyAdmin = 'COMPANY_ADMIN',
    SystemAdmin = 'SYSTEM_ADMIN',
    SystemOwner = 'SYSTEM_OWNER',
    DealCustomerUser = "DEAL_CUSTOMER_USER",
    DealCustomerAdmin = "DEAL_CUSTOMER_ADMIN",
    DealCustomerBooker = "DEAL_CUSTOMER_BOOKER",
    DealCustomerFinance = "DEAL_CUSTOMER_FINANCE",
    PrivateCustomer = "PRIVATE_CUSTOMER",
    CorporateCustomer = "CORPORATE_CUSTOMER",
    TrafficController = "TRAFFIC_CONTROLLER"

}
