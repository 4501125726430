import { toast } from "react-toastify";

const requestAsync = async (fn: (...args: any[]) => Promise<any>, loadingState: any, args: any[], hideError?: boolean) => {
    loadingState(true);
    try {
        await fn(...args);
    } catch (error) {
        const { response, message } = error as any;
        if (!hideError)
            toast.error(response?.data?.message ? response.data.message.interpolateError() : message.interpolateError());
    } finally {
        loadingState(false);
    }
};

const apiHelper = {
    requestAsync
}
export default apiHelper;