import axios, { AxiosResponse } from "axios";
import { authHeader } from "../../helpers/authHeader/auth-header";
import {
    DealOrganization,
    DealOrganizationListDto,
    DealOrganizationSearchRequest,
} from "../../models/dto/dealOrganization/dealOrganization.dto";

const API_URL = `${window.config.baseApiUrl}/customerorganization`;

const getAllCustomerOrganizations = async (onlyTrainCompanies?: boolean): Promise<DealOrganization[]> => {
    const response = await axios
        .get<any, AxiosResponse<DealOrganization[]>>(API_URL + (onlyTrainCompanies ? `/all-train` : `/all`), {
            headers: authHeader(),
        });
    return response.data;
};

const getTrainOrganizationsOfTrafficControlUser = async (id?: number): Promise<DealOrganization[]> => {
    const response = await axios
        .get<any, AxiosResponse<DealOrganization[]>>(`${window.config.baseApiUrl}/traffic-controller/assigned-train-companies${id ? "/" + id : ""}`, {
            headers: authHeader(),
        });
    return response.data;
};
const getCustomerOrganizations = async (
    searchRequest: DealOrganizationSearchRequest
): Promise<DealOrganizationListDto> => {
    const response = await axios
        .get<any, AxiosResponse<DealOrganizationListDto>>(
            API_URL + "/find",
            { params: searchRequest, headers: authHeader() }
        );
    return response.data;
};

const getOrganization = async (id?: number): Promise<DealOrganization> => {
    const response = await axios
        .get<any, AxiosResponse<DealOrganization>>(
            id ? `${API_URL}/detail/${id}` : `${API_URL}/detail`,
            { headers: authHeader() }
        );
    return response.data;
};
const updateOrganization = async (dealOrganization: DealOrganization, isSystemAdmin?: boolean): Promise<DealOrganization> => {
    const response = await axios
        .post<DealOrganization, AxiosResponse<DealOrganization>>(
            isSystemAdmin ? `${API_URL}/update-organization` : `${API_URL}/update-unit-info`,
            dealOrganization, { headers: authHeader() }
        );
    return response.data;
};
const dealOrganizationService = {
    getAllCustomerOrganizations,
    getCustomerOrganizations,
    getOrganization,
    updateOrganization,
    getTrainOrganizationsOfTrafficControlUser
};

export default dealOrganizationService;
