"use client"
import { FacebookProvider, CustomChat } from 'react-facebook';

const app_id = "2999683586830531";
const page_id = "110472285064648";

const ChatMsg = () => {
    return (
      <FacebookProvider appId={app_id} chatSupport>
        <CustomChat pageId={page_id} minimized={true}/>
      </FacebookProvider>
    );
}
export default ChatMsg;