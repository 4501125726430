import { FunctionComponent, ReactElement, useContext, useEffect, useState } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { RoutesContext } from "../../contexts/routes.context";
import authService from "../../services/auth/auth.service";

export interface GuardedRouteProps {
    children: any;
    notAllowedRoles?: GuardedRouteRole[];
    path: string;
    noAuthNeeded?: boolean;
    returnAfterLogin?: boolean;
    exact?: boolean;
}

interface GuardedRouteRole {
    name: string;
    redirectRoute: string;
}

const GuardedRoute = (props: GuardedRouteProps) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>();
    const { routes } = useContext(RoutesContext);
    const location = useLocation();

    useEffect(() => {
        authService.isAuthenticated() ? setIsAuthenticated(true) : setIsAuthenticated(false);
    }, [])
    function render() {
        if (isAuthenticated === false) {
            if (props.noAuthNeeded === true)
                return <Route {...props}>{props.children}</Route>;
            else
                return <Redirect to={props.returnAfterLogin ? routes.auth.login + `?returnUrl=${location.pathname}` : routes.auth.login} />
        }
        if (isAuthenticated === true) {
            var notAllowedRole = props.notAllowedRoles?.find((n: any) => n.name == authService.getCurrentUserRole());
            if (notAllowedRole) {
                return <Redirect to={notAllowedRole.redirectRoute} />
            }
            else {
                return <Route {...props}>{props.children}</Route>;
            }
        }
    }
    return <>{render()}</>

}
export default GuardedRoute;
