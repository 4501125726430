import { Location } from 'history';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

const useBrowserBackStack = () => {
  const history = useHistory();
  const [backStack, setBackStack] = useState<Location[]>([]);
  useEffect(() => {
    history.listen((location, action) => {
      setBackStack(backStack => {
        if (!backStack[backStack.length - 1] || backStack[backStack.length - 1].pathname != location.pathname) {
          return [...backStack, location];
        }
        else if (backStack[backStack.length - 1].pathname == location.pathname) {
          if (backStack[backStack.length - 1].search != location.search) {
            return [...backStack.splice(backStack.length - 2, 1), location]
          }
          return backStack;
        }
        else
          return backStack;
      });
    });
  }, [setBackStack, history]);
  return backStack;
};

export default useBrowserBackStack;