import { Axios, AxiosHeaders } from "axios";
import { Constants } from "../../../constants";

var md5 = require('md5');
export function pepperHeader(data: any, params: any): AxiosHeaders {
    let pepperInLocalStorage = localStorage.getItem('pepper');
    if (!pepperInLocalStorage)
        return new AxiosHeaders({});
    let dataString = pepperInLocalStorage;
    if (data) {
        dataString = JSON.stringify(data) + dataString;
    }
    else if (params) {
        let paramsKeys = Object.keys(params);
        paramsKeys = paramsKeys.sort((a, b) => a.toString().toLowerCase().localeCompare(b.toString().toLowerCase()));
        let values = paramsKeys.map((key) => params[key]);
        dataString = values.join('') + dataString;
    }
    const hash = md5(dataString);
    let headerString = getHeaderName();
    return new AxiosHeaders({ [headerString]: hash });
}
const getHeaderName = () => {
    return Constants.headers.x + "-" + Constants.headers.custom + Constants.headers.header;
}